import { StaticJsonRpcProvider } from '@ethersproject/providers'

export const NULL_BYTES = '0x'
export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000'

export const ETH_ADDRESS_AS_ERC20 = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

const MAINNET_CHAIN_ID = 1
const KOVAN_CHAIN_ID = 42
const RINKEBY_CHAIN_ID = 4
const ROPSTEN_CHAIN_ID = 3

const CHAIN_IDS = {
  MAINNET: MAINNET_CHAIN_ID,
  KOVAN: KOVAN_CHAIN_ID,
  RINKEBY: RINKEBY_CHAIN_ID,
  ROPSTEN: ROPSTEN_CHAIN_ID,
}

const SUPPORTED_CHAINS = [CHAIN_IDS.MAINNET]

const checkIfWalletConnectedToSupportedChain = (
  chainIdWalletIsCurrentlyConnectedTo: number | undefined,
  supportedChains = SUPPORTED_CHAINS,
) => {
  if (!chainIdWalletIsCurrentlyConnectedTo) {
    return undefined
  }
  const isConnectedToSupportedChain = supportedChains.includes(chainIdWalletIsCurrentlyConnectedTo)
  return isConnectedToSupportedChain
}

export { SUPPORTED_CHAINS, CHAIN_IDS, checkIfWalletConnectedToSupportedChain }

export interface AddEthereumChainParameter {
  chainId: number
  chainName: string
  nativeCurrency: {
    name: string
    symbol: string
    decimals: 18
  }
  rpcUrls: string[]
  blockExplorerUrls?: string[]
  iconUrls?: string[]
}

const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
}

const MATIC: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Matic',
  symbol: 'MATIC',
  decimals: 18,
}

interface BasicChainInformation {
  urls: string[]
  name: string
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency']
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls']
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation,
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency
}

export const isNativeToken = (contractAddress: string) => {
  return contractAddress.toLowerCase() === ETH_ADDRESS_AS_ERC20.toLowerCase()
}

export const parseChainIdToNumber = (chainId: string | number): number => {
  return parseInt(chainId.toString(10), 10)
}

export const getRpcUrlForChain = (chainId: string | number): string => {
  switch (parseInt(chainId.toString(10), 10)) {
    case CHAIN_IDS.MAINNET:
    // return 'https://rpc.tenderly.co/fork/613e270f-2c00-4d8f-bc16-476fb5d931d5'
    case CHAIN_IDS.RINKEBY:
      return 'https://eth-rinkeby.alchemyapi.io/v2/dH-CZ9rd8Lz0u4xjDyNQcgPWoZJgU_k4'
    default:
      throw new Error(`No RPC URL found for chainID ${chainId}`)
  }
}

export const getRpcProviderForChain = (chainId: string | number) => {
  const rpcUrl = getRpcUrlForChain(chainId)
  const rpcProvider = new StaticJsonRpcProvider(rpcUrl, parseInt(chainId.toString(10), 10))
  return rpcProvider
}

export const getJsonRpcProviderForChain = (chainId: number | string) => {
  const rpc = new StaticJsonRpcProvider(getRpcUrlForChain(chainId))
  return rpc
}

export const getBlockExplorerUrlForTransaction = (txHash: string, chainId: string) => {
  switch (chainId) {
    case CHAIN_IDS.RINKEBY.toString(10):
      return `https://rinkeby.etherscan.io/tx/${txHash}`
    case CHAIN_IDS.MAINNET.toString(10):
      return `https://etherscan.io/tx/${txHash}`
    default:
      throw new Error(`Unsupport chain: ${chainId}`)
  }
}

// export function getAddChainParameters(chainId: number): AddEthereumChainParameter | number {
//   const chainInformation = CHAINS[chainId]
//   if (isExtendedChainInformation(chainInformation)) {
//     return {
//       chainId,
//       chainName: chainInformation.name,
//       nativeCurrency: chainInformation.nativeCurrency,
//       rpcUrls: chainInformation.urls,
//       blockExplorerUrls: chainInformation.blockExplorerUrls,
//     }
//   } else {
//     return chainId
//   }
// }

// export const CHAINS: { [chainId: number]: BasicChainInformation | ExtendedChainInformation } = {
//   1: {
//     urls: [
//       process.env.infuraKey ? `https://mainnet.infura.io/v3/${process.env.infuraKey}` : undefined,
//       process.env.alchemyKey
//         ? `https://eth-mainnet.alchemyapi.io/v2/${process.env.alchemyKey}`
//         : undefined,
//       'https://cloudflare-eth.com',
//     ].filter((url) => url !== undefined) as string[],
//     name: 'Mainnet',
//   },
//   3: {
//     urls: [
//       process.env.infuraKey ? `https://ropsten.infura.io/v3/${process.env.infuraKey}` : undefined,
//     ].filter((url) => url !== undefined) as string[],
//     name: 'Ropsten',
//   },
//   4: {
//     urls: [
//       process.env.infuraKey ? `https://rinkeby.infura.io/v3/${process.env.infuraKey}` : undefined,
//     ].filter((url) => url !== undefined) as string[],
//     name: 'Rinkeby',
//   },
//   5: {
//     urls: [
//       process.env.infuraKey ? `https://goerli.infura.io/v3/${process.env.infuraKey}` : undefined,
//     ].filter((url) => url !== undefined) as string[],
//     name: 'Görli',
//   },
//   42: {
//     urls: [
//       process.env.infuraKey ? `https://kovan.infura.io/v3/${process.env.infuraKey}` : undefined,
//     ].filter((url) => url !== undefined) as string[],
//     name: 'Kovan',
//   },
//   // Optimism
//   10: {
//     urls: [
//       process.env.infuraKey
//         ? `https://optimism-mainnet.infura.io/v3/${process.env.infuraKey}`
//         : undefined,
//       'https://mainnet.optimism.io',
//     ].filter((url) => url !== undefined) as string[],
//     name: 'Optimism',
//     nativeCurrency: ETH,
//     blockExplorerUrls: ['https://optimistic.etherscan.io'],
//   },
//   69: {
//     urls: [
//       process.env.infuraKey
//         ? `https://optimism-kovan.infura.io/v3/${process.env.infuraKey}`
//         : undefined,
//       'https://kovan.optimism.io',
//     ].filter((url) => url !== undefined) as string[],
//     name: 'Optimism Kovan',
//     nativeCurrency: ETH,
//     blockExplorerUrls: ['https://kovan-optimistic.etherscan.io'],
//   },
//   // Arbitrum
//   42161: {
//     urls: [
//       process.env.infuraKey
//         ? `https://arbitrum-mainnet.infura.io/v3/${process.env.infuraKey}`
//         : undefined,
//       'https://arb1.arbitrum.io/rpc',
//     ].filter((url) => url !== undefined) as string[],
//     name: 'Arbitrum One',
//     nativeCurrency: ETH,
//     blockExplorerUrls: ['https://arbiscan.io'],
//   },
//   421611: {
//     urls: [
//       process.env.infuraKey
//         ? `https://arbitrum-rinkeby.infura.io/v3/${process.env.infuraKey}`
//         : undefined,
//       'https://rinkeby.arbitrum.io/rpc',
//     ].filter((url) => url !== undefined) as string[],
//     name: 'Arbitrum Testnet',
//     nativeCurrency: ETH,
//     blockExplorerUrls: ['https://testnet.arbiscan.io'],
//   },
//   // Polygon
//   137: {
//     urls: [
//       process.env.infuraKey
//         ? `https://polygon-mainnet.infura.io/v3/${process.env.infuraKey}`
//         : undefined,
//       'https://polygon-rpc.com',
//     ].filter((url) => url !== undefined) as string[],
//     name: 'Polygon Mainnet',
//     nativeCurrency: MATIC,
//     blockExplorerUrls: ['https://polygonscan.com'],
//   },
//   80001: {
//     urls: [
//       process.env.infuraKey
//         ? `https://polygon-mumbai.infura.io/v3/${process.env.infuraKey}`
//         : undefined,
//     ].filter((url) => url !== undefined) as string[],
//     name: 'Polygon Mumbai',
//     nativeCurrency: MATIC,
//     blockExplorerUrls: ['https://mumbai.polygonscan.com'],
//   },
// }

// export const URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{
//   [chainId: number]: string[]
// }>((accumulator, chainId) => {
//   const validURLs: string[] = CHAINS[Number(chainId)].urls

//   if (validURLs.length) {
//     accumulator[Number(chainId)] = validURLs
//   }

//   return accumulator
// }, {})

export const getShortenedAddress = (address: string, start: number = 6, end: number = 4) => {
  if (address.length <= start + end) {
    return address
  }
  const shortenedAddress = `${address.slice(0, start)}...${address.slice(-1 * end)}`
  return shortenedAddress
}
