import type { BigNumberish } from '@ethersproject/bignumber'

const ROOT_URL_PRODUCTION = 'https://gobblers.xyz'
const TWITTER_HANDLE = '@artgobblers'

// All deployed contracts are from this commit: https://github.com/FrankieIsLost/art-gobblers/tree/f1f2342d2f9390e99adaa07ee51cdeebcf0d2004
const ADDRESSES_FORKED_MAINET = {
  ART_GOBBLERS: '0x108275cd5a9be248f1e04efcd4fd722419634a13',
  GOOP: '0xe954b287e822e0b0731e3e8825a5507b78861ee0',
  PAGES: '0x8368a91186c76c64f39dd445a7ab8b0b0c508cc2',
}

export interface GameAddresses {
  ART_GOBBLERS: string
  GOO: string
  PAGES: string
}

const ADDRESSES_RINKEBY: GameAddresses = {
  ART_GOBBLERS: '0x2f24ce2164f880ee4b0ced2ee7662bd3703661c1',
  GOO: '0x36a01735ed178ab620ead82ade9c9db14627591b',
  PAGES: '0xe9203183ed781c87800aaed017550cbfe4b34fdc',
  // 0xfaf7affa9a3ade959942a949eecf9ab9a7826d34 -- Community Reserve
  // 0xb4299fa454fba77cc54e0d2d49c3dfc160ee5910 -- Team Reserve
}

const getAddressesForGobblerInstance = (chainId: BigNumberish): GameAddresses => {
  return ADDRESSES_RINKEBY
}

const subgraphRinkberyApiRootUrl = 'https://api.studio.thegraph.com/query/30338/bbtest/v0.0.10'

const getSubgraphAddress = (chainId: BigNumberish) => {
  return subgraphRinkberyApiRootUrl
}

const DEFAULT_CHAIN_ID = '4' // Rinkeby
const DEFAULT_CHAIN_ID_NUMBER = 4

const TEST_USER_WALLET_ADDRESS = '0x1D18077167c1177253555e45B4b5448B11E30b4b'

const SIWE_CONFIG = {
  statement: 'Welcome to Art Gobblers. Please sign in.\nGobble gobble.',
  version: '1',
}

export {
  ROOT_URL_PRODUCTION,
  TWITTER_HANDLE,
  ADDRESSES_RINKEBY,
  getAddressesForGobblerInstance,
  getSubgraphAddress,
  DEFAULT_CHAIN_ID,
  DEFAULT_CHAIN_ID_NUMBER,
  TEST_USER_WALLET_ADDRESS,
  SIWE_CONFIG,
}
