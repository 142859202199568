type IconProps = {
  size: number
  color?: string
}

export function EyeballNoShadow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="none" viewBox="0 0 44 44">
      <circle cx="22" cy="22" r="20.5" fill="#fff" stroke="#080A0C" strokeWidth="3"></circle>
      <circle cx="22" cy="22" r="20.5" fill="#fff" stroke="#080A0C" strokeWidth="3"></circle>
      <path
        fill="#080A0C"
        d="M15 15.5c-.5 0-.5 3-.5 3s-3.5 2-3.5 3 4 .5 4 .5.5 4 1.5 4 2.5-3.5 2.5-3.5 2.649 1.07 3.5 0C23.546 21.186 20 19 20 19s2-3 1-3-4 1.5-4 1.5-1.5-2-2-2z"
      ></path>
    </svg>
  )
}

export function Eyeball({ size }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 142 142"
    >
      <g filter="url(#filter0_dddd_3586_10900)" transform="rotate(-120 71 55)">
        <circle cx="71" cy="55" r="38" fill="#fff"></circle>
        <circle cx="71" cy="55" r="36.5" stroke="#080A0C" strokeWidth="3"></circle>
      </g>
      <path
        fill="#080A0C"
        d="M67.322 71.085c.432.748 4.92-1.843 4.92-1.843s6.014 3.508 7.51 2.644c1.496-.863-2.707-6.415-2.707-6.415s5.552-4.202 4.688-5.698c-.863-1.496-7.394-.717-7.394-.717s-.688-4.886-3.023-5.236c-2.87-.43-3.076 6.763-3.076 6.763s-6.216-.4-5.352 1.095c.864 1.495 5.699 4.688 5.699 4.688s-1.697 3.97-1.265 4.719z"
      ></path>
      <defs>
        <filter
          id="filter0_dddd_3586_10900"
          width="140.013"
          height="140.013"
          x="0.994"
          y="0.994"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="4"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.105882 0 0 0 0 0.101961 0 0 0 0 0.145098 0 0 0 0.12 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3586_10900"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="12"></feOffset>
          <feGaussianBlur stdDeviation="12"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.105882 0 0 0 0 0.101961 0 0 0 0 0.145098 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_3586_10900"
            result="effect2_dropShadow_3586_10900"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="16"></feOffset>
          <feGaussianBlur stdDeviation="16"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.105882 0 0 0 0 0.101961 0 0 0 0 0.145098 0 0 0 0.24 0"></feColorMatrix>
          <feBlend
            in2="effect2_dropShadow_3586_10900"
            result="effect3_dropShadow_3586_10900"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-1" dy="-3"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="effect3_dropShadow_3586_10900"
            result="effect4_dropShadow_3586_10900"
          ></feBlend>
          <feBlend in="SourceGraphic" in2="effect4_dropShadow_3586_10900" result="shape"></feBlend>
        </filter>
      </defs>
    </svg>
  )
}
