import { createClient } from 'urql'

const getGqlClient = (instanceId: number | string) => {
  return rinkebyGqlClient
}

const rinkebyGqlClient = createClient({
  // https://thegraph.com/studio/subgraph/bbtest/
  // Deploy id: QmSez1Pf5qZkg12hBEUnc6dsnBbvMece27S9QaeacguD1A
  url: 'https://api.studio.thegraph.com/query/30338/bbtest/v0.0.3',
})

export { getGqlClient }
