import type { AppProps } from 'next/app'
import NextNProgress from 'nextjs-progressbar'
import { SSRProvider } from '@react-aria/ssr'
import { DefaultSeo } from 'next-seo'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { SessionProvider } from 'next-auth/react'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { Provider as UrqlProvider } from 'urql'
import {
  RainbowKitProvider,
  getDefaultWallets,
  connectorsForWallets,
  wallet,
  darkTheme,
} from '@rainbow-me/rainbowkit'
import { chain, createClient, WagmiConfig, configureChains } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { ThemeProvider } from '../styles/theme'
import { CSSBaseGlobalStyle } from '../styles/base'
import { CSSResetsGlobalStyle } from '../styles/resets'
import { DEFAULT_SITE_TITLE, DEFAULT_SITE_DESCRIPTION, DEFAULT_SITE_NAME } from '../seo'
import { DEFAULT_CHAIN_ID, ROOT_URL_PRODUCTION, TWITTER_HANDLE } from '../config'
import { Header } from '../components/core/Header'
import { CSSGlobalTypography } from '../styles/typography'
import useWindowSize from '../components/draw/hooks/useWindowSize'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-medium-image-zoom/dist/styles.css'
import '@rainbow-me/rainbowkit/styles.css'
import '../styles/globals.css'
import '../styles/fonts.css'

import { SSRContext } from '~/utils/trpc'
import { httpBatchLink } from '@trpc/client/links/httpBatchLink'
import { loggerLink } from '@trpc/client/links/loggerLink'
import { withTRPC } from '@trpc/next'
import { AppRouter } from '~/server/routers/_app'
// import '@dotlottie/player-component';
import superjson from 'superjson'

import { getGqlClient } from '../web3/subgraph'
import { useState } from 'react'

if (process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID) {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN_RUM ?? '',
    site: 'datadoghq.com',
    service: 'art-gobblers-frontend',
    env: process.env.NEXT_PUBLIC_VERCEL_ENV,
    version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'allow',
  })

  datadogRum.startSessionReplayRecording()
}

if (process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN_LOGGER) {
  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN_LOGGER,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
  })
}

const rinkebyRpcUrl = 'https://eth-rinkeby.alchemyapi.io/v2/DcXDChayBMAqA6G-UQZslnMBOOyZxyRV'

const jsonRpcs = jsonRpcProvider({
  rpc: (chainId) => {
    return {
      http: rinkebyRpcUrl,
    }
  },
})

const { chains, provider, webSocketProvider } = configureChains([chain.rinkeby], [jsonRpcs])

const { wallets } = getDefaultWallets({
  appName: 'Gobblers',
  chains,
})

const demoAppInfo = {
  appName: 'Gobblers',
}

const connectors = connectorsForWallets([
  ...wallets,
  {
    groupName: 'Other',
    wallets: [wallet.argent({ chains }), wallet.trust({ chains }), wallet.ledger({ chains })],
  },
])

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider,
})

let alertedLock = false

function ArtGobblersApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient())

  const size = useWindowSize()
  const router = useRouter()

  if (router.pathname === '/draw' && size.width <= 700 && !alertedLock) {
    alertedLock = true
    toast.warning('Your screen is a bit small for the demo! Try turning your device sideways.')
  }

  return (
    <>
      <ToastContainer limit={1} pauseOnHover={false} style={{ fontWeight: 500 }} />

      {/* <DefaultSeo
        title={DEFAULT_SITE_TITLE}
        description={DEFAULT_SITE_DESCRIPTION}
        openGraph={{
          type: 'website',
          locale: 'en_US',
          url: ROOT_URL_PRODUCTION,
          title: DEFAULT_SITE_TITLE,
          description: DEFAULT_SITE_DESCRIPTION,
          site_name: DEFAULT_SITE_NAME,
          images: [
            {
              url: `${ROOT_URL_PRODUCTION}/images/og/social-banner.png`,
              width: 1200,
              height: 627,
              alt: 'ag',
            },
          ],
        }}
        twitter={{
          handle: TWITTER_HANDLE,
          site: TWITTER_HANDLE,
          cardType: 'summary_large_image',
        }}
        additionalMetaTags={[
          {
            name: 'twitter:image',
            content: `${ROOT_URL_PRODUCTION}/images/og/social-banner.png`,
          },
          {
            name: 'twitter:url',
            content: ROOT_URL_PRODUCTION,
          },
        ]}
      /> */}
      <SSRProvider>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <CSSResetsGlobalStyle />
              <CSSBaseGlobalStyle />
              <CSSGlobalTypography />
              <NextNProgress
                color="#5656EC"
                startPosition={0.3}
                stopDelayMs={200}
                height={2}
                showOnShallow={true}
              />
              <WagmiConfig client={wagmiClient}>
                <RainbowKitProvider
                  theme={darkTheme({
                    overlayBlur: 'small',
                    // accentColor: '#15FF03',
                    // accentColorForeground: 'white',
                    // borderRadius: 'small',
                    fontStack: 'system',
                  })}
                  appInfo={{
                    appName: 'Art Gobblers',
                    learnMoreUrl: 'https://gobblers.art/faq',
                  }}
                  chains={chains}
                >
                  <SessionProvider session={pageProps.session} refetchInterval={0}>
                    <UrqlProvider value={getGqlClient(DEFAULT_CHAIN_ID)}>
                      <Header />
                      <Component {...pageProps} />
                    </UrqlProvider>
                  </SessionProvider>
                </RainbowKitProvider>
              </WagmiConfig>
            </Hydrate>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </ThemeProvider>
      </SSRProvider>
    </>
  )
}

const Mobile = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`
const MobileText = styled.div`
  max-width: 80vw;
`

const Tablet = styled.div``

function getBaseUrl() {
  if (typeof window !== 'undefined') {
    return ''
  }
  // reference for vercel.com
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`
  }

  // // reference for render.com
  if (process.env.RENDER_INTERNAL_HOSTNAME) {
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`
  }

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`
}

const trpcMiddleware = withTRPC<AppRouter>({
  config() {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */
    return {
      /**
       * @link https://trpc.io/docs/links
       */
      links: [
        // adds pretty logs to your console in development and logs errors in production
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === 'development' ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
        httpBatchLink({
          url: `${getBaseUrl()}/api/trpc`,
        }),
      ],
      /**
       * @link https://trpc.io/docs/data-transformers
       */
      transformer: superjson,
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: true,
  /**
   * Set headers or status code when doing SSR
   */
  responseMeta(opts) {
    const ctx = opts.ctx as SSRContext

    if (ctx.status) {
      // If HTTP status set, propagate that
      return {
        status: ctx.status,
      }
    }

    const error = opts.clientErrors[0]
    if (error) {
      // Propagate http first error from API calls
      return {
        status: error.data?.httpStatus ?? 500,
      }
    }
    // For app caching with SSR see https://trpc.io/docs/caching
    return {}
  },
})

export default trpcMiddleware(ArtGobblersApp)
