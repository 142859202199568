import create from 'zustand'
import { immerMiddleware } from './middleware'

type WalletConnectionTypes = 'metamask' | 'walletconnect' | 'walletlink'

type IAppStore = {
  isLoginModalOpen: boolean
  hasActiveLoginAttempt: WalletConnectionTypes | null
  setIsLoginModalOpen: (updatedValue: boolean) => void
  setHasActiveLoginAttempt: (updatedAttempt: WalletConnectionTypes | null) => void
}

const useAppStore = create<IAppStore>(
  immerMiddleware((set) => ({
    isLoginModalOpen: false,
    hasActiveLoginAttempt: null,
    setHasActiveLoginAttempt: (updatedAttempt) => {
      set((draft) => {
        draft.hasActiveLoginAttempt = updatedAttempt
      })
    },
    setIsLoginModalOpen: (updatedValue) => {
      set((draft) => {
        draft.isLoginModalOpen = updatedValue
        draft.hasActiveLoginAttempt = null
      })
    },
  })),
)

export { useAppStore }
